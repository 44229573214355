import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const QuestionsLayout = ({ children }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isCreate = useMemo(() => pathname.includes('create'), [pathname]);
	const isEdit = useMemo(() => pathname.includes('edit'), [pathname]);

	return (
		<Content className="content">
			<div className="content-header">
				<Breadcrumb
					className="breadcrumb"
					items={[
						{
							title: 'Сесії',
						},
						{
							title: 'Питання',
						},
					]}
				/>
				<div className="content-title">
					<ul className="content-title__list">
						<li className="content-title__item">Питання</li>
					</ul>
					{!isCreate && !isEdit && (
						<Button
							onClick={() => navigate('create')}
							className="add-category-btn add-advertisements-btn"
						>
							<PlusOutlined style={{ fontSize: '16px' }} />
							Додати питання до сесії
						</Button>
					)}
				</div>
			</div>
			{children}
		</Content>
	);
};

export default QuestionsLayout;
