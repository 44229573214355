import { createSlice } from '@reduxjs/toolkit';
import { addOrderStatus, deleteOrderStatus, editOrderStatus, fetchOrderStatuses } from './thunks';

const orderStatusesSlice = createSlice({
	name: 'orderStatuses',
	initialState: { items: [], loading: false, error: null },
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrderStatuses.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchOrderStatuses.fulfilled, (state, action) => {
				state.loading = false;
				state.items = action.payload;
			})
			.addCase(fetchOrderStatuses.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(addOrderStatus.fulfilled, (state, action) => {
				state.items.push(action.payload);
			})
			.addCase(editOrderStatus.fulfilled, (state, action) => {
				const index = state.items.findIndex((item) => item.id === action.payload.id);
				if (index !== -1) state.items[index] = action.payload;
			})
			.addCase(deleteOrderStatus.fulfilled, (state, action) => {
				state.items = state.items.filter((item) => item.id !== action.payload);
			});
	},
});

export const OrderStatusesReducer = orderStatusesSlice.reducer;
