import { createSlice } from '@reduxjs/toolkit';

const AdvertisingSlice = createSlice({
	name: 'business/Advertising',
	initialState: {
		companies: null,
		kits: null,
		productsPromotion: null,
		promotional: null,
	},
	reducers: {},
	extraReducers: () => {},
});

export const AdvertisingReducer = AdvertisingSlice.reducer;
// export const {} = AdvertisingSlice.actions;
