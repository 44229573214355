import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from 'guards/AuthGuard';
import { Loadable } from './Loadable';
import CityCouncilRouter from './CityCouncilRoutes';
import CommunityRouter from './CommunityRouter';
import BusinessModuleRouter from './BusinessModuleRoutes';

export default function AuthRouter() {
	return (
		<AuthGuard>
			<Routes>
				<Route path="/" element={<AdminHomePage />}>
					<Route index element={<GeneralDashboard />} />
					<Route path="city-council/*" element={<CityCouncilRouter />} />
					<Route path="community/*" element={<CommunityRouter />} />
					<Route path="business/*" element={<BusinessModuleRouter />} />
					<Route
						path="meeting/:meetId"
						element={
							<StreamWrapper>
								<StreamVideoClientComponent />
							</StreamWrapper>
						}
					/>
				</Route>
			</Routes>
		</AuthGuard>
	);
}

const AdminHomePage = Loadable(lazy(() => import('pages/AdminHomePage/AdminHomePage')));
const GeneralDashboard = Loadable(
	lazy(() => import('components/GeneralDashboard/GeneralDashboard'))
);
const StreamVideoClientComponent = Loadable(lazy(() => import('components/StreamVideoClient')));
const StreamWrapper = Loadable(lazy(() => import('components/StreamVideoClient/StreamWrapper')));
