import styled from 'styled-components';
import { Divider as DividerAntd } from 'antd';
import ReactQuill from 'react-quill';

export const Divider = styled(DividerAntd)({
	height: '16px',
	width: '100%',
	backgroundColor: '#f8f9fc',
	margin: 0,
});

export const Wrapper = styled('div')({
	backgroundColor: '#fff',
	width: '360px',
	margin: '0 auto',
	paddingTop: '24px',
	'& .ant-divider': {
		margin: 0,
		marginBottom: '24px',
	},
});

export const FormWrapper = styled('div')({
	margin: '24px',
	padding: 0,
	backgroundColor: '#ffff',
	width: 'calc(100vw - 285px)',
	height: 'calc(100vh - 198px)',
	overflowY: 'scroll',
});

export const TextEditor = styled(ReactQuill)({
	margin: '0 24px 24px',
	'& .ql-container,& .ql-editor': {
		minHeight: '565px',
	},
});
