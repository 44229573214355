import axios from 'axios';
import { message } from 'utils/GlobalComponent';

// axios.defaults.baseURL = 'http://localhost:3001/';
axios.defaults.baseURL = 'https://hromada-api.vercel.app/';

export const sendNotify = async (mess) => {
	try {
		await axios.post('send-notification', mess);
		message.success('Notification send successfully');
	} catch (error) {
		message.error(error?.response?.data?.message ?? error?.message ?? 'Error send notification');
	}
};

export const subscribeTopic = async (topics, token) => {
	try {
		await axios.post('subscribe', { topics, token });
	} catch (error) {
		console.log(error);
		// message.error(error?.response?.data?.message ?? error?.message ?? 'Error subscribe');
	}
};

export const unsubscribeTopic = async (topics, token) => {
	try {
		await axios.post('unsubscribe', { topics, token });
	} catch (error) {
		console.log(error);
		// message.error(error?.response?.data?.message ?? error?.message ?? 'Error unsubscribe');
	}
};
