import { createSlice } from '@reduxjs/toolkit';

const PaymentManagementSlice = createSlice({
	name: 'business/paymentManagement',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const PaymentManagementReducer = PaymentManagementSlice.reducer;
// export const {} = PaymentManagementSlice.actions;
