import { LoadingOutlined } from '@ant-design/icons';

const LoaderAbsolute = () => {
	return (
		<div
			style={{
				position: 'absolute',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: 'calc(100vw - 265px)',
				backdropFilter: 'blur(3px)',
				backgroundColor: 'transparent',
				zIndex: 9999,
				right: 0,
			}}
		>
			<LoadingOutlined style={{ fontSize: '90px', color: '#1677ff' }} />
		</div>
	);
};
export default LoaderAbsolute;
