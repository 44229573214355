export const handleConnectedChats = (state, { payload }) => {
	state.connectedChats = payload;
};

export const handleAllTelegramChats = (state, { payload }) => {
	state.allTelegramChats = payload;
};

export const handleSelectedChatMessages = (state, { payload }) => {
	state.selectedChatMessages = payload;
};

export const handleUnreadCountMessages = (state, { payload }) => {
	state.unreadCountMessages = payload;
};

export const handleNewMessages = (state, { payload }) => {
	state.newMessages = payload;
};
