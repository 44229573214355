import { createSlice } from '@reduxjs/toolkit';
import { fetchClientCategories, addNewClientCategory, deleteClientCategory } from '../crm/thunks';

const clientCategoriesSlice = createSlice({
	name: 'clientCategories',
	initialState: {
		items: [],
		loading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchClientCategories.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchClientCategories.fulfilled, (state, action) => {
				state.loading = false;
				state.items = action.payload;
			})
			.addCase(fetchClientCategories.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(addNewClientCategory.fulfilled, (state, action) => {
				state.items.push(action.payload);
			})
			.addCase(deleteClientCategory.fulfilled, (state, action) => {
				state.items = state.items.filter((category) => category.id !== action.payload);
			});
	},
});

export const ClientCategoriesReducer = clientCategoriesSlice.reducer;
