import { Badge, List } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const UserItem = ({ channel, user, createNewChannel, unreadResponse }) => {
	const [activeUserChat, setActiveUserChat] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [unreadCount, setUnreadCount] = useState(0);

	useEffect(() => {
		if (channel) {
			setIsActive(channel.cid === activeUserChat.channelId);
		}
	}, [activeUserChat, channel]);

	useEffect(() => {
		unreadResponse &&
			unreadResponse.channels &&
			setUnreadCount(
				unreadResponse.channels.find((el) => el.channel_id === user.channelId)?.unread_count
			);
	}, [channel, unreadResponse, user.channelId]);

	return (
		<List.Item
			key={user.id}
			className={`users-list-item ${isActive ? 'active' : ''}`}
			onClick={() => {
				createNewChannel(user);
				setActiveUserChat(user);
			}}
		>
			<Badge dot={user.online} color="green" offset={[10, 12]}>
				<Badge count={unreadCount} offset={[25, -4]}>
					<p className="preview-item-text" style={{ ...(user.online && { margin: 0 }) }}>
						{user.name}
					</p>
					{!user.online && (
						<p className="preview-item-title">{`Останній візит: ${dayjs(
							new Date(user.last_active)
						).format('DD-MM-YYYY hh:mm')}`}</p>
					)}
				</Badge>
			</Badge>
		</List.Item>
	);
};

export default UserItem;
