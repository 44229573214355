import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loadable } from './Loadable';
import StreamChatComponent from 'components/StreamChatComponent';

export default function CityCouncilRouter() {
	return (
		<Routes>
			<Route path="/" element={<CityCouncil />}>
				<Route index element={<CityCouncilInformation />} />
				<Route path="services" element={<CityCouncilServices />} />
				<Route path="services/edit-category" element={<CityCouncilServicesEditCategory />} />
				<Route path="services/category" element={<CityCouncilServicesCategoryList />} />
				<Route path="services/category/edit" element={<CityCouncilServicesEditService />} />
				{/* Документи */}
				<Route path="docs" element={<CityCouncilDocs />} />
				<Route path="docs-constructor" element={<CityCouncilDocsConstructorList />} />
				<Route path="docs-constructor/edit" element={<CityCouncilDocsConstructorEdit />} />
				<Route path="blanks" element={<CityCouncilDocsBlanks />} />
				<Route path="blanks/edit/:id" element={<CityCouncilDocsEditBlanks />} />
				<Route path="blanks/create" element={<CityCouncilDocsCreateBlanks />} />
				<Route path="reports" element={<CityCouncilDocsReports />} />
				<Route path="reports/edit/:id" element={<CityCouncilDocsEditReports />} />
				<Route path="reports/create" element={<CityCouncilDocsCreateReports />} />
				<Route path="sessions" element={<CityCouncilDocsSessions />} />
				<Route path="sessions/edit/:id" element={<CityCouncilDocsEditSessions />} />
				<Route path="sessions/create" element={<CityCouncilDocsCreateSessions />} />
				{/* Дошка */}
				<Route path="advertisements" element={<CityCouncilAdvertisements />} />
				<Route path="advertisements/edit" element={<EditAdvertisementForm />} />
				<Route path="events" element={<Events />} />
				<Route path="events/edit" element={<EditEventForm />} />
				<Route path="news" element={<News />} />
				<Route path="news/edit" element={<EditNewsForm />} />
				<Route path="careers" element={<CityCouncilCareers />} />
				<Route path="polls" element={<Polls />} />
				<Route path="polls/edit/:id" element={<EditPolls />} />
				<Route path="polls/create" element={<CreatePolls />} />
				<Route path="polls/statistics/:id" element={<StatisticsPolls />} />
				{/* Departments */}
				<Route path="departments" element={<CityCouncilDepartments />} />
				<Route path="departments/edit" element={<EditDepartment />} />
				{/* Staff */}
				<Route path="staff" element={<CityCouncilStaff />} />
				<Route path="staff/edit" element={<EditProfile />} />
				<Route path="schedule" element={<CityCouncilSchedule />} />
				<Route path="staff-requests" element={<StaffRequestsList />} />
				<Route path="users" element={<CityCouncilUsers />} />
				<Route path="chats/*" element={<Chats />}>
					<Route path="chats/:id" element={<Chats />} />
				</Route>
				<Route path="streamChats/*" element={<StreamChatComponent />}>
					<Route path="streamChats/:id" element={<StreamChatComponent />} />
				</Route>
				<Route path="calendar" element={<CityCouncilCalendar />} />
				<Route path="appointment" element={<Appointment />} />
				<Route path="appointment/table" element={<AppointmentTable />} />
				<Route path="notifications" element={<PushNotifications />} />
				<Route path="notifications" element={<PushNotifications />} />
				<Route path="notifications/create" element={<CreatePushNotifications />} />
				{/* Problems */}
				<Route path="problems" element={<Navigate to="urgent" />} />
				<Route path="problems/urgent" element={<Problems />} />
				<Route path="problems/archived" element={<ProblemsArchived />} />
				{/* Налаштування */}
				<Route path="settings" element={<CityCouncilSettings />} />
				<Route path="admins" element={<CityCouncilAdmins />} />
				<Route path="faq" element={<CityCouncilFaq />} />
				<Route path="help" element={<HowToUseList />} />
				<Route path="help/:id/:suggestionId" element={<HowToUseItem />} />
				<Route path="special-days" element={<SpecialDays />} />
			</Route>
		</Routes>
	);
}

const CityCouncil = Loadable(lazy(() => import('pages/CityCouncilPage/CityCouncilPage')));
const CityCouncilInformation = Loadable(
	lazy(() => import('components/CityCouncilComponents/HomePage/CityCouncilHomePage'))
);
const CityCouncilServices = Loadable(
	lazy(() => import('components/CityCouncilComponents/Services/CityCouncilServices'))
);
const CityCouncilServicesEditCategory = Loadable(
	lazy(() => import('components/CityCouncilComponents/Services/CityCouncilServicesEditCategory'))
);
const CityCouncilServicesCategoryList = Loadable(
	lazy(() => import('components/CityCouncilComponents/Services/CityCouncilServicesCategoryList'))
);
const CityCouncilServicesEditService = Loadable(
	lazy(() => import('components/CityCouncilComponents/Services/CityCouncilServicesEditService'))
);
const CityCouncilDocs = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocumentsAndStatements/CityCouncilDocs'))
);
const CityCouncilDocsConstructorList = Loadable(
	lazy(
		() => import('components/CityCouncilComponents/Docs/Constructor/CityCouncilDocsConstructorList')
	)
);
const CityCouncilDocsConstructorEdit = Loadable(
	lazy(
		() => import('components/CityCouncilComponents/Docs/Constructor/CityCouncilDocsConstructorEdit')
	)
);
const CityCouncilDocsBlanks = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocsBlanks/CityCouncilDocsBlanks'))
);
const CityCouncilDocsReports = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocsReports/CityCouncilDocsReports'))
);
const CityCouncilDocsSessions = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocsSessions/CityCouncilDocsSessions'))
);
const CityCouncilAdvertisements = Loadable(
	lazy(
		() =>
			import('components/CityCouncilComponents/Board/BoardAdvertisements/CityCouncilAdvertisements')
	)
);
const EditAdvertisementForm = Loadable(
	lazy(
		() => import('components/CityCouncilComponents/Board/BoardAdvertisements/EditAdvertisementForm')
	)
);
const Events = Loadable(
	lazy(() => import('components/CityCouncilComponents/Board/BoardEvents/Events'))
);
const EditEventForm = Loadable(
	lazy(() => import('components/CityCouncilComponents/Board/BoardEvents/EditEventForm'))
);
const News = Loadable(lazy(() => import('components/CityCouncilComponents/Board/BoardNews/News')));
const EditNewsForm = Loadable(
	lazy(() => import('components/CityCouncilComponents/Board/BoardNews/EditNewsForm'))
);
const CityCouncilCareers = Loadable(
	lazy(() => import('components/CityCouncilComponents/Board/BoardCareers/CityCouncilCareers'))
);
const Polls = Loadable(lazy(() => import('components/Polls/Polls')));
const Problems = Loadable(lazy(() => import('components/Problems')));
const ProblemsArchived = Loadable(lazy(() => import('components/Problems/ProblemsArchived')));
const CityCouncilDepartments = Loadable(
	lazy(() => import('components/CityCouncilComponents/Departments/CityCouncilDepartments'))
);
const CityCouncilStaff = Loadable(
	lazy(() => import('components/CityCouncilComponents/Staff/AllStaff/CityCouncilStaff'))
);
const EditProfile = Loadable(
	lazy(() => import('components/CityCouncilComponents/Staff/AllStaff/EditProfile'))
);
const CityCouncilUsers = Loadable(
	lazy(() => import('components/CityCouncilComponents/Users/CityCouncilUsers'))
);
const Chats = Loadable(lazy(() => import('components/Chats/Chats')));
const CityCouncilCalendar = Loadable(
	lazy(() => import('components/CityCouncilComponents/Calendar/CityCouncilCalendar'))
);
const PushNotifications = Loadable(lazy(() => import('components/PushNotifications')));
const CreatePushNotifications = Loadable(
	lazy(() => import('components/PushNotifications/CreatePushNotifications'))
);
const CityCouncilSettings = Loadable(
	lazy(
		() =>
			import(
				'components/CityCouncilComponents/Settings/EditFormCityCouncilSettings/CityCouncilSettings'
			)
	)
);
const CityCouncilAdmins = Loadable(
	lazy(() => import('components/CityCouncilComponents/Settings/Admins/Admins'))
);
const CityCouncilFaq = Loadable(
	lazy(() => import('components/CityCouncilComponents/Settings/CityCouncilFaq'))
);
const SpecialDays = Loadable(
	lazy(() => import('components/CityCouncilComponents/Settings/SpecialDays/SpecialDays'))
);
const CityCouncilHelp = Loadable(
	lazy(() => import('components/CityCouncilComponents/CityCouncilHelp'))
);
const CityCouncilDocsEditBlanks = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocsBlanks/CityCouncilDocsEditBlanks'))
);
const CityCouncilDocsCreateBlanks = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocsBlanks/CityCouncilDocsCreateBlanks'))
);
const CityCouncilDocsEditReports = Loadable(
	lazy(() => import('components/CityCouncilComponents/Docs/DocsReports/CityCouncilDocsEditReports'))
);
const CityCouncilDocsCreateReports = Loadable(
	lazy(
		() => import('components/CityCouncilComponents/Docs/DocsReports/CityCouncilDocsCreateReports')
	)
);
const CityCouncilDocsEditSessions = Loadable(
	lazy(
		() => import('components/CityCouncilComponents/Docs/DocsSessions/CityCouncilDocsEditSessions')
	)
);
const CityCouncilDocsCreateSessions = Loadable(
	lazy(
		() => import('components/CityCouncilComponents/Docs/DocsSessions/CityCouncilDocsCreateSessions')
	)
);
const EditPolls = Loadable(lazy(() => import('components/Polls/EditPolls')));
const CreatePolls = Loadable(lazy(() => import('components/Polls/CreatePolls')));
const StatisticsPolls = Loadable(lazy(() => import('components/Polls/StatisticsPolls')));
const CityCouncilSchedule = Loadable(
	lazy(() => import('components/CityCouncilComponents/Staff/Schedule/StaffSchedule'))
);
const StaffRequestsList = Loadable(
	lazy(() => import('components/CityCouncilComponents/Staff/StaffRequests/StaffRequestsList'))
);
const EditDepartment = Loadable(
	lazy(
		() =>
			import('components/CityCouncilComponents/Departments/components/tabs/general/EditDepartment')
	)
);
const Appointment = Loadable(lazy(() => import('components/Appointment')));
const AppointmentTable = Loadable(lazy(() => import('components/Appointment/AppointmentTable')));

const HowToUseList = Loadable(lazy(() => import('components/HowToUse/HowToUseList')));
const HowToUseItem = Loadable(lazy(() => import('components/HowToUse/HowToUseItem')));
