import { createSlice } from '@reduxjs/toolkit';

const CorrespondenceMGSlice = createSlice({
	name: 'business/CorrespondenceMG',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const CorrespondenceMGReducer = CorrespondenceMGSlice.reducer;
// export const {} = CorrespondenceMGSlice.actions;
