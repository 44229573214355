import { createSlice } from '@reduxjs/toolkit';

const ClientsCorrespondenceSlice = createSlice({
	name: 'business/ClientsCorrespondence',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const ClientsCorrespondenceReducer = ClientsCorrespondenceSlice.reducer;
// export const {} = ClientsCorrespondenceSlice.actions;
