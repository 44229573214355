import { createSlice } from '@reduxjs/toolkit';

const ModulesSlice = createSlice({
	name: 'business/modules',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const ModulesReducer = ModulesSlice.reducer;
// export const {} = ModulesSlice.actions;
