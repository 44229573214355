import Loader from 'components/Loader/Loader';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { authLoadedSelector, authSelector } from 'redux/auth/selectors';

const GuestGuard = ({ children }) => {
	const isAuth = useSelector(authSelector);
	const isLoaded = useSelector(authLoadedSelector);
	const { state } = useLocation();

	if (!isLoaded) return <Loader fullScreen />;

	return !isAuth ? children : <Navigate to={state ?? '/'} />;
};

export default GuestGuard;
