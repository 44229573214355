import Loader from 'components/Loader/Loader';
import useGetSingleDoc from 'hooks/useGetSingleDoc';
import { useDispatch, useSelector } from 'react-redux';
import QuestionsLayout from './QuestionsLayout';
import { FormWrapper } from './utils/Questions.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'utils/GlobalComponent';
import { scrollToElement } from 'constants/scrollToElement';
import { loadingSelector } from 'redux/root/selectors';
import LoaderAbsolute from 'components/LoaderAbsolute/LoaderAbsolute';
import { editVotingQuestionsThunk } from '../../../../redux/community/voting-questions/thunks';
import QuestionsForm from './components/QuestionsForm';

const QuestionsEdit = () => {
	const dispatch = useDispatch();
	const { sessionId } = useParams();
	const communityUuid = useSelector((state) => state.community.communityUuid);

	const { data, isLoading } = useGetSingleDoc(
		`communities/${communityUuid}/sessions/${sessionId}/questions`
	);
	const loading = useSelector(loadingSelector);
	const navigate = useNavigate();

	const onFinish = (newData) => {
		const editedData = { ...data, ...newData, date: newData?.date?.$d || null };

		dispatch(editVotingQuestionsThunk({ data: editedData, sessionId }))
			.unwrap()
			.then(() => navigate(-1));
	};

	const onFinishFailed = (error) => {
		error.errorFields.forEach((element) => {
			if (element.name[0] === 'name') {
				message.error('Заповніть Заголовок', 5);
				scrollToElement('name');
			}
		});
	};

	if (isLoading) return <Loader fullScreen />;
	return (
		<QuestionsLayout>
			<FormWrapper className="form">
				{loading && <LoaderAbsolute />}
				{data && <QuestionsForm onFinish={onFinish} onFinishFailed={onFinishFailed} data={data} />}
			</FormWrapper>
		</QuestionsLayout>
	);
};

export default QuestionsEdit;
