import { subscribeTopic } from 'api/notification';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { authSelector } from 'redux/auth/selectors';
import { communityIdSelector } from 'redux/city-council/cityCouncilSelectors';
import { townesSelector } from 'redux/problems/selectors';
import { getTownesByCommunityThunk } from 'redux/problems/thunks';

const AuthGuard = ({ children }) => {
	const isAuth = useSelector(authSelector);
	const token = useSelector((state) => state.auth.firebaseToken);
	const location = useLocation();
	const communityId = useSelector(communityIdSelector);
	const townes = useSelector(townesSelector);
	const topics = useMemo(() => townes?.map((el) => el.id.toString()), [townes]);

	const dispatch = useDispatch();

	useEffect(() => {
		topics?.length > 0 && subscribeTopic(topics, token);
	}, [topics]);

	useEffect(() => {
		communityId && dispatch(getTownesByCommunityThunk());
	}, [communityId]);

	return isAuth ? children : <Navigate to="/auth/login" state={location} />;
};

export default AuthGuard;
