export const handleDealsStatuses = (state, { payload }) => {
	state.dealsStatuses = payload;
};

export const handleDeals = (state, { payload }) => {
	state.deals = payload;
};

export const handleActiveDeal = (state, { payload }) => {
	state.activeDeal = payload;
};

export const handleTasksStatuses = (state, { payload }) => {
	state.tasksStatuses = payload;
};

export const handleTasks = (state, { payload }) => {
	state.tasks = payload;
};

export const handleTaskTemplates = (state, { payload }) => {
	state.taskTemplates = payload;
};

export const handleArchivedTasks = (state, action) => {
	state.archivedTasks = action.payload;
};

export const handleTaskHistory = (state, action) => {
	state.taskHistory = action.payload;
};

export const handlePreloadGoogleFiles = (state, { payload }) => {
	state.preloadGoogleFiles = payload;
};

export const handleTasksChecklist = (state, { payload }) => {
	state.tasksChecklist = payload;
};

export const handleMoreOnlineResponsiblePersons = (state, { payload }) => {
	state.moreOnlineResponsiblePersons = payload;
};

export const handleAboutLeadOnlineDynamicOwnFields = (state, { payload }) => {
	state.aboutLeadOnlineDynamicOwnFields = payload;
};

export const handleArchiveDeals = (state, { payload }) => {
	state.archivedDeals = payload;
};

export const handleUnreadDealsTasks = (state, { payload }) => {
	state.unreadDealsTasks = payload;
};

export const handleDealLeadDetailHistory = (state, { payload }) => {
	state.dealLeadDetailHistory = payload;
};
