import { createSlice } from '@reduxjs/toolkit';

const ControlPanelSlice = createSlice({
	name: 'business/ControlPanel',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const ControlPanelReducer = ControlPanelSlice.reducer;
// export const {} = ControlPanelSlice.actions;
