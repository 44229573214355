import { createSlice } from '@reduxjs/toolkit';
import {
	handleCRMStatuses,
	handleOrders,
	handleActiveOrder,
	handleMoreOnlineResponsiblePersons,
	handleAboutLeadOnlineDynamicOwnFields,
	handleTasksStatuses,
	handleTasks,
	handleTaskTemplates,
	handlePreloadGoogleFiles,
	handleArchivedTasks,
	handleTaskHistory,
	handleTasksChecklist,
	handleLeads,
	handleServices,
	handleArchivedLeads,
	handleLeadDetailHistory,
	handleUnreadTasks,
	handleTasksWithLeadId,
} from './helpers';
import {
	addService,
	dearchiveLeadThunk,
	deleteService,
	fetchArchivedLeadsThunk,
	fetchServiceById,
	fetchServices,
	updateService,
} from './thunks';

const CRMSlice = createSlice({
	name: 'business/crm',
	initialState: {
		clientOptions: [],
		inputValue: '',
		phoneValue: '',
		nameValue: '',
		prefixUrl: '/assets/img/avatar.png',
		companyInputValue: '',
		companyPrefixUrl: './assets/img/default_company.png',
		companyCategories: [],
		CRMStatuses: [],
		taskHistory: [],
		orders: null,
		activeOrder: null,
		moreOnlineResponsiblePersons: null,
		aboutLeadOnlineDynamicOwnFields: null,
		tasksStatuses: [],
		tasks: null,
		taskTemplates: null,
		unreadTasks: 0,
		tasksWithLeadId: null,
		preloadGoogleFiles: null,
		archivedTasks: [],
		tasksChecklist: null,
		products: [],
		editingProduct: null,
		leads: [],
		companyOptions: [],
		services: null,
		activeService: null,
		archivedLeads: [],
		leadDetailHistory: null,
	},
	reducers: {
		addTaskHistory: (state, action) => {
			state.taskHistory.push(action.payload);
		},
		setClientOptions: (state, action) => {
			state.clientOptions = action.payload;
		},
		setInputValue: (state, action) => {
			state.inputValue = action.payload;
		},
		setPrefixUrl: (state, action) => {
			state.prefixUrl = action.payload;
		},
		setPhoneValue: (state, action) => {
			state.phoneValue = action.payload;
		},
		setNameValue: (state, action) => {
			state.nameValue = action.payload;
		},
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		addProduct: (state, action) => {
			state.products.push(action.payload);
		},
		updateProduct: (state, action) => {
			const index = state.products.findIndex((product) => product.id === action.payload.id);
			if (index !== -1) {
				state.products[index] = action.payload;
			}
		},
		setEditingProduct: (state, action) => {
			state.editingProduct = action.payload;
		},
		clearEditingProduct: (state) => {
			state.editingProduct = null;
		},
		setCompanyOptions: (state, action) => {
			state.companyOptions = action.payload;
		},
		setCompanyInputValue: (state, action) => {
			state.companyInputValue = action.payload;
		},
		setCompanyPrefixUrl: (state, action) => {
			state.companyPrefixUrl = action.payload;
		},
		setCompanyCategories: (state, action) => {
			state.companyCategories = action.payload;
		},
		setCRMStatuses: handleCRMStatuses,
		setOrders: handleOrders,
		setActiveOrder: handleActiveOrder,
		setMoreOnlineResponsiblePersons: handleMoreOnlineResponsiblePersons,
		setAboutLeadOnlineDynamicOwnFields: handleAboutLeadOnlineDynamicOwnFields,
		setTasksStatuses: handleTasksStatuses,
		setTasks: handleTasks,
		setTaskTemplates: handleTaskTemplates,
		setTasksWithLeadId: handleTasksWithLeadId,
		setUnreadTasks: handleUnreadTasks,
		setPreloadGoogleFiles: handlePreloadGoogleFiles,
		setArchivedTasks: handleArchivedTasks,
		setTaskHistory: handleTaskHistory,
		setTasksChecklist: handleTasksChecklist,
		setLeads: handleLeads,
		setServices: handleServices,
		setArchivedLeads: handleArchivedLeads,
		setLeadDetailHistory: handleLeadDetailHistory,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchServices.fulfilled, (state, action) => {
				state.services = action.payload;
				state.status = 'succeeded';
			})
			.addCase(fetchServiceById.fulfilled, (state, action) => {
				state.activeService = action.payload;
				state.status = 'succeeded';
			})
			.addCase(addService.fulfilled, (state, action) => {
				state.services.push(action.payload);
				state.status = 'succeeded';
			})
			.addCase(updateService.fulfilled, (state, action) => {
				const index = state.services.findIndex((service) => service.id === action.payload.id);
				if (index !== -1) {
					state.services[index] = action.payload;
				}
				state.status = 'succeeded';
			})
			.addCase(deleteService.fulfilled, (state, action) => {
				state.services = state.services.filter((service) => service.id !== action.payload);
				state.status = 'succeeded';
			})
			.addCase(fetchArchivedLeadsThunk.fulfilled, (state, action) => {
				state.archivedLeads = action.payload;
			});
		builder
			.addCase(dearchiveLeadThunk.fulfilled, (state, action) => {
				state.archivedLeads = state.archivedLeads.filter((lead) => lead.id !== action.payload);
			})
			.addMatcher(
				(action) => action.type.startsWith('services/') && action.type.endsWith('/pending'),
				(state) => {
					state.status = 'loading';
				}
			)
			.addMatcher(
				(action) => action.type.startsWith('services/') && action.type.endsWith('/rejected'),
				(state, action) => {
					state.status = 'failed';
					state.error = action.payload;
				}
			);
	},
});

export const CRMReducer = CRMSlice.reducer;

export const {
	setProducts,
	addProduct,
	addTaskHistory,
	updateProduct,
	setEditingProduct,
	clearEditingProduct,
	setCRMStatuses,
	setOrders,
	setClientOptions,
	setInputValue,
	setPrefixUrl,
	setNameValue,
	setPhoneValue,
	setActiveOrder,
	setMoreOnlineResponsiblePersons,
	setAboutLeadOnlineDynamicOwnFields,
	setTasksStatuses,
	setTasks,
	setTasksWithLeadId,
	setTaskTemplates,
	setPreloadGoogleFiles,
	setArchivedTasks,
	setTaskHistory,
	setTasksChecklist,
	setLeads,
	setCompanyOptions,
	setCompanyInputValue,
	setCompanyPrefixUrl,
	setCompanyCategories,
	setServices,
	setArchivedLeads,
	setLeadDetailHistory,
	setUnreadTasks,
} = CRMSlice.actions;
