import {
	createUserWithEmailAndPassword,
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
} from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { storage } from '../../firebase';
import axios from 'axios';
import { authSlice, setApiToken, setLoaded } from './authReducer';
import { localTextErrorAuth } from 'constants/errorAuthLocalText';
const db = getFirestore();
const {
	updateUserProfile,
	authSignOut,
	authError,
	isLoadingChange,
	updPermissions,
	setLoggedUser,
} = authSlice.actions;

export const authSignUpUser =
	({ email, password }) =>
	async (dispatch, getSatte) => {
		dispatch(isLoadingChange(true));

		try {
			const auth = getAuth();

			await createUserWithEmailAndPassword(auth, email, password);
		} catch (error) {
			dispatch(authError({ updError: true }));
			console.log('error', error);
			console.log('error.message', error.message);
			dispatch(authError({ updError: true, errorMsg: localTextErrorAuth(error.code) }));
			return false;
		} finally {
			dispatch(isLoadingChange(false));
		}
	};

export const authSignInUser =
	({ email, password }) =>
	async (dispatch, getState) => {
		const API_HEROKU = process.env.REACT_APP_API_HEROKU;

		dispatch(isLoadingChange(true));

		try {
			const auth = getAuth();
			const { user } = await signInWithEmailAndPassword(auth, email, password);

			const bodyParameters = {
				email,
				password,
			};

			const { data } = await axios.post(`${API_HEROKU}/auth/register`, bodyParameters);

			console.log('data:', data);

			localStorage.setItem('apiToken', data.token);

			dispatch(setLoggedUser(user));

			dispatch(setApiToken(data.token));
		} catch (error) {
			console.log('error', error);
			console.log('error.code', error.code);
			console.log('error.message', error.message);
			dispatch(authError({ updError: true, errorMsg: localTextErrorAuth(error.code) }));
		} finally {
			dispatch(isLoadingChange(false));
		}
	};

export const authSignOutUser = () => async (dispatch, getState) => {
	const auth = getAuth();
	await signOut(auth);
	await dispatch(authSignOut());

	localStorage.removeItem('apiToken');
};

export const authStateCahngeUser = () => async (dispatch, getState) => {
	try {
		const auth = getAuth();

		// const currentToken = localStorage.getItem('apiToken');

		// if (!currentToken) return dispatch(authSignOutUser());

		await onAuthStateChanged(auth, async (user) => {
			if (user) {
				dispatch(authError({ updError: false, errorMsg: null }));

				dispatch(updateUserProfile({ stateChange: true }));

				dispatch(setLoggedUser(user));
			} else dispatch(setLoaded(true));
		});
	} catch (error) {
		console.log('error', error);
		console.log('error.code', error.code);
		console.log('error.message', error.message);
	}
};

export const getPermissions = () => async (dispatch, getState) => {
	dispatch(isLoadingChange(true));
	try {
		const auth = getAuth();

		const q = query(
			collection(db, 'permission'),
			where('users', 'array-contains', auth.currentUser.uid)
		);
		const querySnapshot = await getDocs(q);

		const permissionUser = querySnapshot.docs.map((doc) => doc.data());

		const permissionsIds = permissionUser
			.filter((item) => item.placeId || item.communityId || item.businessId)
			.map((item) =>
				item.placeId
					? {
							id: item.placeId,
							type: 'place',
						}
					: item.communityId
						? {
								id: item.communityId,
								type: 'community',
							}
						: {
								id: item.businessId,
								type: 'business',
							}
			);

		let permissions = [];

		for (let v = 0; v < permissionsIds.length; v++) {
			const collectionName =
				permissionsIds[v].type === 'place'
					? 'places'
					: permissionsIds[v].type === 'community'
						? 'communities'
						: 'business_collection';

			const q = query(
				collection(db, collectionName),
				where('id', '==', permissionsIds[v].id),
				collectionName === 'communities'
					? where('available', '==', true)
					: where('deleted', '==', false)
			);
			const querySnapshot = await getDocs(q);
			const res = querySnapshot.docs.map((doc) => doc.data());

			if (res.length > 0) {
				permissions.push({
					...res[0],
					type: permissionsIds[v].type,
					name:
						permissionsIds[v].type === 'place'
							? res[0]?.name
							: permissionsIds[v].type === 'community'
								? res[0]?.publicName?.uk
								: res[0]?.businessInfo?.name,
					image:
						permissionsIds[v].type === 'place'
							? res[0]?.images?.[0]
							: permissionsIds[v].type === 'community'
								? 'assets/img/community_logo.jpg'
								: res[0]?.businessInfo?.images?.[0],
				});
			} else {
				// console.warn(
				// 	`No data found for ID: ${permissionsIds[v].id} in collection: ${collectionName}`
				// );
			}
		}

		const permissionsSort = permissions
			.filter((el) => el.type !== 'business' || el.status === 'verified')
			.sort((a, b) => a.type.localeCompare(b.type));

		dispatch(updPermissions(permissionsSort));
	} catch (error) {
		console.log('error', error);
		console.log('error.code', error.code);
		console.log('error.message', error.message);
	} finally {
		dispatch(isLoadingChange(false));
	}
};
