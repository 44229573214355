import { Button, DatePicker, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { Divider, Wrapper } from '../utils/Questions.styled';
import { Divider as DividerAnt } from 'antd';
import React from 'react';

const QuestionsForm = ({ onFinish, onFinishFailed, data }) => {
	const navigate = useNavigate();
	const dateFormat = 'DD/MM/YYYY';

	return (
		<>
			<Form
				layout="vertical"
				name="nest-messages"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				initialValues={{
					name: data?.name || null,
					date: null,
					reporter: data?.reporter || null,
					number: data?.number || null,
				}}
			>
				<Wrapper>
					<DividerAnt>Питання</DividerAnt>
					<Form.Item
						name="name"
						label="Назва"
						rules={[
							{
								required: true,
								message: "Обов'язкове поле",
							},
						]}
					>
						<TextArea id="name" placeholder="Назва" autoSize={true} />
					</Form.Item>
					<Form.Item
						name="number"
						label="№"
						rules={[
							{
								required: true,
								message: "Обов'язкове поле",
							},
						]}
					>
						<TextArea id="number" placeholder="Номер" autoSize={true} />
					</Form.Item>
					<Form.Item name="date" label="Дата питання">
						<DatePicker format={dateFormat} />
					</Form.Item>
					<Form.Item
						name="reporter"
						label="№"
						rules={[
							{
								required: true,
								message: "Обов'язкове поле",
							},
						]}
					>
						<TextArea id="reporter" placeholder="Доповідач" autoSize={true} />
					</Form.Item>
				</Wrapper>
				<Divider />
				<Form.Item className="general-form-buttons-submit">
					<Button htmlType="button" style={{ marginRight: '24px' }} onClick={() => navigate(-1)}>
						Закрити
					</Button>
					<Button type="primary" htmlType="submit">
						{data ? 'Зберегти' : 'Створити'}
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};
export default QuestionsForm;
