import { createSlice } from '@reduxjs/toolkit';
import { handleSos } from './sos/helpers';
import { handleChats } from './categories-chats/helpers';
import { handleFaq } from './categories-faq/helpers';
import { handleEstablishmentList, handlePlacesList } from './categories-establishment/helpers';
import { handlePlacesMainList } from './places/helpers';
import { handleCommunity, handleCurrentGallery, handleGallery } from './community-about/helpers';
import {
	handleCurrentTown,
	handleTownCurrentGallery,
	handleTownGallery,
	handleTowns,
} from './towns/helpers';
import { handleHowToUseItem, handleHowToUseList } from './how-to-use/helpers';
import { handleTransport } from './categories-transport/helpers';
import { handleCulture } from './categories-culture/helpers';
import { handleActivities } from './categories-activities/helpers';
import { handleCommunityUuid, handleVotingSessions } from './voting-sessions/helpers';
import { handleVotingQuestions } from './voting-questions/helpers';

const state = {
	activeCommunityId: null,
	communityUuid: null,
	categories: [],
	directoryType: [],
	loadingData: false,
	establishmentList: [],
	currentEstablishment: {
		name: null,
		id: null,
		directoryCategory: null,
	},
	imagesCurrentEstablishment: [],
	users: [],
	chats: [],
	messages: {},
	sosList: null,
	categoryChats: null,
	categoryFaq: null,
	placesList: null,
	placesMainList: null,
	currentCommunity: null,
	communityId: null,
	gallery: null,
	currentGallery: null,
	towns: null,
	townId: null,
	howToUseList: null,
	howToUseItem: null,
	howToUseId: null,
	categoryTransport: null,
	categoryCulture: null,
	categoryActivities: null,
	sessionsList: null,
	questionsList: null,
};

export const communitySlice = createSlice({
	name: 'community',
	initialState: state,
	reducers: {
		updateCategories: (_, { payload }) => ({
			...state,
			categories: payload,
		}),
		updateActiveCommunityId: (state, { payload }) => ({
			...state,
			activeCommunityId: payload,
		}),
		updDirectoryType: (state, { payload }) => ({
			...state,
			directoryType: payload,
		}),
		onLoadingData: (state) => ({
			...state,
			loadingData: true,
		}),
		offLoadingData: (state) => ({
			...state,
			loadingData: false,
		}),
		updEstablishmentList: (state, { payload }) => ({
			...state,
			establishmentList: payload,
		}),
		updCurrentEstablishment: (state, { payload }) => ({
			...state,
			currentEstablishment: payload,
		}),
		updImagesCurrentEstablishment: (state, { payload }) => ({
			...state,
			imagesCurrentEstablishment: payload,
		}),
		updateUsers: (state, { payload }) => ({
			...state,
			users: payload,
		}),
		updateChats: (state, { payload }) => ({
			...state,
			chats: payload,
		}),
		updateMessages: (state, { payload }) => ({
			...state,
			messages: { ...state.messages, ...payload },
		}),
		setSosOperation: handleSos,
		setChatsOperation: handleChats,
		setFaqOperation: handleFaq,
		setPlacesListOperation: handlePlacesList,
		setEstablishmentListOperation: handleEstablishmentList,
		setPlacesMainListOperation: handlePlacesMainList,
		setGalleryOperation: handleGallery,
		setCommunityOperation: handleCommunity,
		setCurrentGallery: handleCurrentGallery,
		setTownGalleryOperation: handleTownGallery,
		setTownsOperation: handleTowns,
		setCurrentTownOperation: handleCurrentTown,
		setCurrentGalleryOperation: handleTownCurrentGallery,
		setHowToUseListOperation: handleHowToUseList,
		setHowToUseItemOperation: handleHowToUseItem,
		setTransportOperation: handleTransport,
		setCultureOperation: handleCulture,
		setActivitiesOperation: handleActivities,
		setVotingSessionsOperation: handleVotingSessions,
		setCommunityUuidOperation: handleCommunityUuid,
		setVotingQuestionsOperation: handleVotingQuestions,
	},
});

export const {
	setSosOperation,
	setChatsOperation,
	setFaqOperation,
	setPlacesListOperation,
	setEstablishmentListOperation,
	setPlacesMainListOperation,
	setGalleryOperation,
	setCommunityOperation,
	setCurrentGallery,
	setTownGalleryOperation,
	setTownsOperation,
	setCurrentTownOperation,
	setCurrentGalleryOperation,
	setHowToUseListOperation,
	setHowToUseItemOperation,
	updateActiveCommunityId,
	setTransportOperation,
	setCultureOperation,
	setActivitiesOperation,
	setVotingSessionsOperation,
	setCommunityUuidOperation,
	setVotingQuestionsOperation,
} = communitySlice.actions;
