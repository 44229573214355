import { createSlice } from '@reduxjs/toolkit';

const ProductManagementSlice = createSlice({
	name: 'business/ProductManagement',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const ProductManagementReducer = ProductManagementSlice.reducer;
// export const {} = ProductManagementSlice.actions;
