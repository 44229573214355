import { createSlice } from '@reduxjs/toolkit';
import { handleAssistantList, handleSelectedAssistant } from './helpers';

const aiSlice = createSlice({
	name: 'business/ai',
	initialState: {
		assistantList: [],
		selectedAssistant: null,
	},
	reducers: {
		setAssistantList: handleAssistantList,
		setSelectedAssistant: handleSelectedAssistant,
	},
});

export const AiReducer = aiSlice.reducer;
export const { setAssistantList, setSelectedAssistant } = aiSlice.actions;
