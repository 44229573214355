import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore/lite';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { store } from 'redux/store';
import { setFirebaseToken } from 'redux/auth/authReducer';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage();

const messaging = getMessaging(app);

if ('Notification' in window) {
	Notification.requestPermission()
		.then(async (permission) => {
			if (permission === 'granted') {
				const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
				return getToken(messaging, {
					vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
					serviceWorkerRegistration: registration,
				});
			} else if (permission === 'denied') {
				console.warn('permission declined');
			}
		})
		.then((currentToken) => {
			if (currentToken) {
				store.dispatch(setFirebaseToken(currentToken));
			} else {
				console.log('No registration token available. Request permission to generate one.');
			}
		})
		.catch((error) => {
			console.error('Error permission:', error);
		});
}

export const onMessListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});

export { auth, db, storage, app };
