export const handleTowns = (state, { payload }) => {
	state.towns = payload;
};

export const handleCurrentTown = (state, { payload }) => {
	state.currentTown = payload.currentTown;
	state.townId = payload.id;
};

export const handleTownCurrentGallery = (state, { payload }) => {
	state.currentGallery = payload;
};

export const handleTownGallery = (state, { payload }) => {
	state.gallery = payload;
};
