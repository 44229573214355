import { createSlice } from '@reduxjs/toolkit';
import {
	handleDealsStatuses,
	handleDeals,
	handleActiveDeal,
	handleTasksStatuses,
	handleTasks,
	handleTaskTemplates,
	handlePreloadGoogleFiles,
	handleArchivedTasks,
	handleTaskHistory,
	handleTasksChecklist,
	handleMoreOnlineResponsiblePersons,
	handleAboutLeadOnlineDynamicOwnFields,
	handleUnreadDealsTasks,
	handleDealLeadDetailHistory,
} from './helpers';
import { fetchArchivedDealsThunk } from './thunks';

const DealsSlice = createSlice({
	name: 'business/deals',
	initialState: {
		dealsStatuses: [],
		deals: null,
		activeDeal: null,
		moreOnlineResponsiblePersons: null,
		aboutLeadOnlineDynamicOwnFields: null,
		dealsList: null,
		tasksStatuses: [],
		tasks: null,
		unreadDealsTasks: 0,
		taskTemplates: null,
		preloadGoogleFiles: null,
		archivedTasks: [],
		tasksChecklist: null,
		archivedDeals: [],
		dealLeadDetailHistory: null,
	},
	reducers: {
		setDealLeadDetailHistory: handleDealLeadDetailHistory,
		setUnreadDealsTasks: handleUnreadDealsTasks,
		setDealsStatuses: handleDealsStatuses,
		setDeals: handleDeals,
		setActiveDeal: handleActiveDeal,
		setDealsList: (state, { payload }) => {
			state.dealsList = payload;
		},
		setTasksStatuses: handleTasksStatuses,
		setTasks: handleTasks,
		setTaskTemplates: handleTaskTemplates,
		setPreloadGoogleFiles: handlePreloadGoogleFiles,
		setArchivedTasks: handleArchivedTasks,
		setTaskHistory: handleTaskHistory,
		setTasksChecklist: handleTasksChecklist,
		setMoreOnlineResponsiblePersons: handleMoreOnlineResponsiblePersons,
		setAboutLeadOnlineDynamicOwnFields: handleAboutLeadOnlineDynamicOwnFields,
		setArchivedDeals: (state, { payload }) => {
			state.archivedDeals = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchArchivedDealsThunk.fulfilled, (state, action) => {
			console.log('Archived deals fetched:', action.payload);
			state.archivedDeals = action.payload;
		});
	},
});

export const DealsReducer = DealsSlice.reducer;

export const {
	setDealLeadDetailHistory,
	setDealsStatuses,
	setDeals,
	setActiveDeal,
	setDealsList,
	setTasksStatuses,
	setTasks,
	setTaskTemplates,
	setPreloadGoogleFiles,
	setArchivedTasks,
	setTaskHistory,
	setTasksChecklist,
	setMoreOnlineResponsiblePersons,
	setAboutLeadOnlineDynamicOwnFields,
	setArchivedDeals,
	setUnreadDealsTasks,
} = DealsSlice.actions;
