import { createSlice } from '@reduxjs/toolkit';
import { addOrder, deleteOrder, fetchOrders, updateOrder } from '../crm/thunks';
import { fetchArchivedOrdersThunk } from '../deals/thunks';

const ordersTableSlice = createSlice({
	name: 'ordersTable',
	initialState: {
		orders: [],
		archivedOrders: [],
		loading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrders.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchOrders.fulfilled, (state, action) => {
				state.orders = action.payload;
				state.loading = false;
			})
			.addCase(fetchOrders.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(addOrder.fulfilled, (state, action) => {
				state.orders.push(action.payload);
			})
			.addCase(updateOrder.fulfilled, (state, action) => {
				const index = state.orders.findIndex((order) => order.id === action.payload.id);
				if (index !== -1) state.orders[index] = action.payload;
			})
			.addCase(deleteOrder.fulfilled, (state, action) => {
				state.orders = state.orders.filter((order) => order.id !== action.payload);
			})
			.addCase(fetchArchivedOrdersThunk.fulfilled, (state, action) => {
				state.archivedOrders = action.payload;
			})
			.addCase(fetchArchivedOrdersThunk.rejected, (state, action) => {
				state.error = action.payload;
			});
	},
});

export const OrdersTableReducer = ordersTableSlice.reducer;
