export const handleProblems = (state, { payload }) => {
	state.problems = payload;
};
export const handleArchivedProblems = (state, { payload }) => {
	state.archivedProblems = payload;
};
export const handleClearProblems = (state) => {
	state.problems = null;
	state.archivedProblems = null;
};
export const handleProblemsColumns = (state, { payload }) => {
	state.columns = payload;
};

export const handleTownes = (state, { payload }) => {
	state.townes = payload;
};

export const handleNewProblemsCounter = (state, { payload }) => {
	state.newProblemsCounter = payload;
};
