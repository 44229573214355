import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authStateCahngeUser } from 'redux/auth/authOperations';
import 'overlayscrollbars/overlayscrollbars.css';
import Router from 'utils/RootRoutes';
import GlobalComponent, { notification } from './utils/GlobalComponent';
import { onMessListener } from './firebase';
import { Col, Row } from 'antd';
import { unsubscribeTopic } from 'api/notification';
import { townesSelector } from 'redux/problems/selectors';

export const App = () => {
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.firebaseToken);
	const townes = useSelector(townesSelector);
	const topics = useMemo(() => townes?.map((el) => el.id.toString()), [townes]);

	useEffect(() => {
		dispatch(authStateCahngeUser());

		const unsubscribe = onMessListener().then((res) => {
			notification.info({
				message: res.notification.title,
				description: (
					<Row justify="space-between">
						<Col span={12}>{res.notification.body}</Col>
						<Col>
							{res.notification.image && <img src={res.notification.image} width="100px" />}
						</Col>
					</Row>
				),
				placement: 'topRight',
				duration: null,
			});
		});

		return () => {
			unsubscribe.catch((err) => console.log(err));
			unsubscribeTopic(topics, token);
		};
	}, []);

	return (
		<>
			<Router />
			<GlobalComponent />
		</>
	);
};
