import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getFirestore,
	collection,
	query,
	where,
	onSnapshot,
	addDoc,
	updateDoc,
	doc,
	Timestamp,
	getDocs,
} from 'firebase/firestore';
import '../../../firebase';
import { setVotingQuestionsOperation } from '../communityReducer';
import { message } from '../../../utils/GlobalComponent';

const db = getFirestore();

export const getVotingQuestionsThunk = createAsyncThunk(
	'community/sos/getSessions',
	async ({ sessionId }, { getState, dispatch }) => {
		const communityId = getState().community.communityUuid;

		const q = query(collection(db, `communities/${communityId}/sessions/${sessionId}/questions`));

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const result = [];
			querySnapshot.forEach((doc) => {
				result.push({
					...doc.data(),
					key: doc.id,
				});
			});

			dispatch(setVotingQuestionsOperation(result));
		});

		return unsubscribe;
	}
);

export const addVotingQuestionsThunk = createAsyncThunk(
	'sos/addSession',
	async ({ data, sessionId }, { rejectWithValue, getState }) => {
		const communityId = getState().community.communityUuid;

		const path = 'communities/' + communityId + '/sessions/' + sessionId + '/questions';

		try {
			const docRef = await addDoc(collection(db, path), data);
			if (docRef) {
				await updateDoc(doc(db, path, docRef.id), {
					id: docRef.id,
					createdAt: Timestamp.fromDate(new Date()),
				});
				message.success('Документ успішно створено');
			}
		} catch (error) {
			message.error('Під час створення виникла помилка');
			rejectWithValue(error.message ?? 'Created with error');
		}
	}
);

export const editVotingQuestionsThunk = createAsyncThunk(
	'sos/editVotingSession',
	async ({ data, sessionId }, { rejectWithValue, getState }) => {
		const communityId = getState().community.communityUuid;
		const path = 'communities/' + communityId + '/sessions/' + sessionId + '/questions';

		try {
			await updateDoc(doc(db, path, data.id), {
				...data,
				updatedAt: Timestamp.fromDate(new Date()),
			});

			message.success('Документ успішно змінено');
		} catch (error) {
			message.error('Під час створення виникла помилка');
			rejectWithValue(error.message ?? 'Created with error');
		}
	}
);
