import { createSlice } from '@reduxjs/toolkit';

const ContactsSlice = createSlice({
	name: 'business/contacts',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const ContactsReducer = ContactsSlice.reducer;
// export const {} = ContactsSlice.actions;
